// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animations-js": () => import("./../src/pages/animations.js" /* webpackChunkName: "component---src-pages-animations-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reading-js": () => import("./../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */),
  "component---src-pages-result-js": () => import("./../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */)
}

